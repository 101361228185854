import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 37;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>ランニングしている女性は美しい</li>
        <li>スポーツ選手の努力と素質</li>
        <li>田舎のガソリンスタンドはかわいいお姉ちゃんがいると流行る</li>
        <li>今年は走れるときは走りたい</li>
        <li>自分はスポーツができるという思い込み</li>
        <li>ズンバでダンスをしながらトレーニング</li>
        <li>鈍臭いおばさんのフィットネス</li>
        <li>ゴスペルの縦乗りへのこだわり</li>
        <li>ダンスもランニングに影響する？</li>
        <li>久しぶりにあんなキレッキレのデブ見た</li>
        <li>年とっても生きたいように生きるおじいちゃん</li>
        <li>人は環境によってできることできないこと変わってくる</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
